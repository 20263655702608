import React, {Component} from 'react';
import { Button } from 'react-native';


import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TouchableHighlight,
  Animated,
  Easing,

} from 'react-native';
import logo from './onepi_logo.jpg';

class App extends Component {
  state = {
    spinValue: new Animated.Value(0),
  };

  onClick = () => {
    const wasRotated = this.state.spinValue._value === 1;
    Animated.timing(this.state.spinValue, {
      toValue: wasRotated ? 0 : 1,
      duration: 250,
      easing: Easing.linear,
    }).start();
  };

  render() {
    const spin = this.state.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });

    return (

      <View style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}>
        <View style={{height: 30}} />
        <View
          style={{
            alignItems: 'center'
          }}>
          <Image source={require('./logo_onepi.png')} style={[styles.logo]}/>
        </View>
        <View style={{height: 30}} />
        <View style={{height: 320, backgroundColor: '#00a3ff'}}>
        <View style={{alignItems: 'center'}}>
              <Text style={{color:'white',fontSize: 15,fontWeight: 'bold'}}>
                Earn by renting a space to a business sponsor
              </Text>
              <Text style={{color:'white',fontSize: 15,fontWeight: 'bold'}}>
                on your social media content.
              </Text>
              <Text style={{color:'white',fontSize: 15,fontWeight: 'bold'}}>
                Get sponsored and earn incentives through
              </Text>
              <Text style={{color:'white',fontSize: 15,fontWeight: 'bold'}}>
                regular social media activities today
              </Text>             
        </View>
        <View style={{height: 10}} />
        <View style={{alignItems: 'center'}}>              
              <Image source={require('./images/107259768.jpg')} style={{width:300,height:200}} resizeMode='contain'/>
        </View>
        </View>
        <View style={{height: 20}} />
        <View style={{alignItems: 'center',paddingTop:"50"}}>
          <View style={{flex:1,flexDirection:'row'}}>
            <View style={{flex:0.4}}>
            <Image source={require('./download_ios.png')} style={{width:130,height:50}}/>
            </View>
            <View style={{flex:0.2}}>
            </View> 
            <View style={{flex:0.4}}>
            <Image source={require('./download_android.png')} style={{width:150,height:50}}/>
            </View>
          </View>
        </View>
      </View>

      

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:9000
  },
  logo: {
    width: 200,
    height: 80,
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
    alignItems: 'center'
  },
  text: {
    color: '#fff',
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: '#1B95E0',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
});

export default App;
